// import CONTRACT_ABI from "./abi.json";

const COLORS = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  WARM_GRAY: "#AEA79F",
  COOL_GRAY: "#636165",
  TEXT_GRAY: "#111111",
  GREEN: "#43A047",
  BLUE: "#0289A8",
  RED: "#B9121B",
  DARK_RED: "#441A19",
  LIGHT_BLUE: "#02A4CA",
  DARK_BLUE: "#004B8D",
};

const CONTRACT_ADDRESS = "0xd97de154bfedfee68a2694f737f01cb60067294e";

const DRAGGABLE_TYPES = {
  NFT: "nft",
};

const STATUS = {
  EMPTY: "EMPTY",
  FULL: "FULL",
  LOCKED: "LOCKED",
  MINE: "MINE",
  MINE_LOCKED: "MINE_LOCKED",
  DRAGGING: "DRAGGING",
  OVER: "OVER",
};

// TODO: hide this stuff
const MOARLIS_APP_ID =
  "CcWLDq0KT4IyH971KbAJOcu8aH7RqsUm9MAjDGdERoLTMxnuquLK47ySM7Sl9l4b";

const PINATA_URL = "https://alchemy.mypinata.cloud/ipfs";

const MATIC_CONNECTION_CONFIG = [
  {
    chainId: "0x89",
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "Avalanche",
      symbol: "MATIC",
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://explorer.matic.network"],
  },
];

const TIME_TO_RESET = 60; // in seconds

export {
  COLORS,
  // CONTRACT_ABI,
  CONTRACT_ADDRESS,
  DRAGGABLE_TYPES,
  MOARLIS_APP_ID,
  PINATA_URL,
  STATUS,
  MATIC_CONNECTION_CONFIG,
  TIME_TO_RESET,
};
