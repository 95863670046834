import { useEffect, useState } from "react";
import Header from "./Header";
import { ChapbookContainer } from "./styles";

const About = () => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const chapbookContainer = document.querySelector("#chapbook-container");
  const [[pdfHeight, pdfWidth], setPdfDimensions] = useState([0, 0]);

  useEffect(() => {
    const _pdfHeight =
      window.innerHeight -
      44 - // height of Header
      66;
    const _pdfWidth = Math.min(_pdfHeight + 130, window.innerWidth - 48);
    setPdfDimensions([_pdfHeight, _pdfWidth]);
  }, [setPdfDimensions]);

  useEffect(() => {
    if (chapbookContainer) {
      chapbookContainer.addEventListener("click", () => {
        if (showHamburgerMenu) {
          setShowHamburgerMenu(false);
        }
      });
    }
  }, [chapbookContainer]);

  return (
    <>
      <Header
        hideConnect={true}
        showHamburgerMenu={showHamburgerMenu}
        setShowHamburgerMenu={setShowHamburgerMenu}
      />
      <ChapbookContainer id={"chapbook-container"}>
        {pdfHeight > 0 && pdfWidth > 0 && (
          <iframe
            src="https://drive.google.com/file/d/1LTDCEk12Sqd0CPiuMSVjf6YwrvRDr0dc/preview"
            width={pdfWidth}
            height={pdfHeight}
            allow="autoplay"
          ></iframe>
        )}
      </ChapbookContainer>
    </>
  );
};

export default About;
