import { useDrag, useDrop } from "react-dnd";
import { LockButton, Square } from "./styles";
import { DRAGGABLE_TYPES, STATUS } from "./constants";

const Position = ({ handleDrop, position, toggleLock, activeLocks }) => {
  const {
    imageUrl,
    mine,
    locked,
    name,
    nft_id: nftId,
    font,
    backgroundColor,
    wordColor,
  } = position;

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_TYPES.NFT,
      canDrop: () => !locked,
      drop: (item) => handleDrop(position, item),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [handleDrop, locked, position]
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      item: { id: nftId, imageUrl, name, font, backgroundColor, wordColor },
      type: DRAGGABLE_TYPES.NFT,
      canDrag: () => !!mine && !locked,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [mine, nftId, name, locked, imageUrl, font, backgroundColor, wordColor]
  );

  let status;
  if (!nftId) status = STATUS.EMPTY;
  if (nftId) status = STATUS.FULL;
  if (mine) status = STATUS.MINE;
  if (isOver) status = STATUS.OVER;
  if (locked) status = STATUS.LOCKED;
  if (isOver && locked) status = STATUS.OVER_LOCKED;
  if (mine && locked) status = STATUS.MINE_LOCKED;
  if (isDragging) status = STATUS.DRAGGING;

  const lockImage = locked ? "/lock-locked.svg" : "/lock-unlocked.svg";

  return (
    <Square ref={drop} status={status}>
      {mine && (locked || activeLocks < 2) && (
        <LockButton onClick={() => toggleLock(position)}>
          <img width="8" height="10" src={lockImage} />
        </LockButton>
      )}
      {imageUrl ? (
        <div ref={drag}>
          <img src={imageUrl} />
        </div>
      ) : null}
    </Square>
  );
};

export default Position;
