import { useEffect, useState } from "react";
import Header from "./Header";
import { AboutContainer } from "./styles";

const Video = ({ srcUrl }) => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const aboutContainer = document.querySelector("#about-container");

  useEffect(() => {
    if (aboutContainer) {
      aboutContainer.addEventListener("click", () => {
        if (showHamburgerMenu) {
          setShowHamburgerMenu(false);
        }
      });
    }
  }, [aboutContainer]);

  return (
    <>
      <Header
        hideConnect={true}
        showHamburgerMenu={showHamburgerMenu}
        setShowHamburgerMenu={setShowHamburgerMenu}
      />
      <AboutContainer id={"about-container"} centerItems={true}>
        {srcUrl ? <video src={srcUrl} controls /> : null}
      </AboutContainer>
    </>
  );
};

export default Video;
