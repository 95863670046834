import { useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
// import Web3 from "web3";
import Web3EthContract from "web3-eth-contract";
import { v4 as uuidv4 } from "uuid";
import { OwnContainer, SharedContainer, MainContainer } from "./styles";
import Header from "./Header";
import Nft from "./Nft";
import Position from "./Position";
import Modal from "./Modal";
import CountdownBar from "./CountdownBar";
import {
  // CONTRACT_ABI,
  DRAGGABLE_TYPES,
  MOARLIS_APP_ID,
  PINATA_URL,
  MATIC_CONNECTION_CONFIG,
  TIME_TO_RESET,
} from "./constants";

const getNfts = async (nftIds) => {
  let transformedNfts = [];
  await Promise.all(
    nftIds.map((tokenId) => {
      return fetch(`${process.env.REACT_APP_API_URL}/nft/${tokenId}`);
    })
  )
    .then((res) => Promise.all(res.map((_res) => _res.json())))
    .then((data) => {
      const _transformedNfts = data.map(({ data = [] }) => {
        const [nft = {}] = data;
        const {
          id,
          name,
          image_url,
          background_color,
          word_color,
          font,
          token_id: _id,
        } = nft;
        const imageUrl = `${PINATA_URL}/${image_url.slice("ipfs://".length)}`;
        return {
          id: id || _id,
          name,
          imageUrl,
          background_color,
          word_color,
          font,
        };
      });
      transformedNfts = [...transformedNfts, ..._transformedNfts];
    })
    .catch(console.error);
  return transformedNfts;
};

const Main = () => {
  // const [contract, setContract] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [nftIds, setNftIds] = useState([]);
  const [allNfts, setAllNfts] = useState([]);
  const [allNftIds, setAllNftIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState([]);
  const [locks, setLocks] = useState(null);
  const [populatedPositions, setPopulatedPositions] = useState([]);
  const [poemUrl, setPoemUrl] = useState("");
  const [message, setMessage] = useState("");
  const [account, setAccount] = useState("");
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [activeLocks, setActiveLocks] = useState(0);
  const [timeLeft, setTimeLeft] = useState(TIME_TO_RESET);

  const getPositions = useCallback(async () => {
    let _positions;
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/positions`);
      const { data } = await res.json();
      _positions = data.sort((a, b) => {
        if (a.id < b.id) return -1;
        return 1;
      });
    } catch (err) {
      console.error("could not fetch res:", { err });
      return;
    }
    setPositions(_positions);
  }, [setPositions]);

  const getLocks = useCallback(async () => {
    let _locks;
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/locks`);
      const { data } = await res.json();
      _locks = data || [];
    } catch (err) {
      console.error("could not fetch res:", { err });
      return;
    }
    setLocks(_locks);
  }, [setLocks]);

  useEffect(() => {
    getPositions();
    getLocks();
    setTimeout(() => {
      window.location.reload();
    }, TIME_TO_RESET * 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
  }, [timeLeft, setTimeLeft]);

  const mainContainer = document.querySelector("#main-container");

  useEffect(() => {
    if (mainContainer) {
      mainContainer.addEventListener("click", () => {
        if (showHamburgerMenu) {
          setShowHamburgerMenu(false);
        }
      });
    }
  }, [mainContainer]);

  const adjustContainerHeight = useCallback(() => {
    const height = `${document.querySelector("html").clientHeight - 118}px`;
    const mainContainerStyle =
      document.querySelector("#main-container")?.style || {};
    mainContainerStyle.height = height;
    mainContainerStyle.maxHeight = height;
  });

  useEffect(() => {
    if (loading) return;
    adjustContainerHeight();
    // TODO: something better
    setInterval(() => {
      adjustContainerHeight();
    }, 1000);
  }, [loading]);

  const handleConnect = useCallback(async () => {
    const { ethereum } = window;
    const metamaskInstalled = ethereum?.isMetaMask;
    if (metamaskInstalled) {
      Web3EthContract.setProvider(ethereum);
      // const web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        if (!accounts.length) throw new Error("no account");
        setAccount(accounts[0]);
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId === "137") {
          try {
            const res = await fetch(
              `${process.env.REACT_APP_API_URL}/nfts/${accounts[0]}`
            );
            const { data } = await res.json();
            const nftIds = data || [];
            const formattedNfts = await getNfts(nftIds);
            const positionedNftIds = populatedPositions
              .map(({ nft_id }) => {
                return nft_id ? nft_id : null;
              })
              .filter((nft_id) => !!nft_id);
            const filteredNfts = formattedNfts.filter(
              (nft) => !positionedNftIds.includes(nft.id)
            );
            setNfts(filteredNfts);
            setNftIds(filteredNfts.map(({ id }) => id));
            setAllNfts(formattedNfts);
            setAllNftIds(formattedNfts.map(({ id }) => id));
            let _activeLocks = 0;
            for (const { active, user_address } of locks) {
              if (
                active &&
                user_address.toLowerCase() === accounts[0].toLowerCase()
              ) {
                _activeLocks += 1;
              }
            }
            setActiveLocks(_activeLocks);
          } catch (error) {
            setMessage("Sorry, we weren't able to fetch your NFTs.");
          }
          // const _contract = new Web3EthContract(CONTRACT_ABI, CONTRACT_ADDRESS);
          // setContract(_contract);
          ethereum.on("accountsChanged", () => {
            window.location.reload();
          });
          ethereum.on("chainChanged", () => {
            if (networkId !== "137") {
              ethereum
                .request({
                  method: "wallet_addEthereumChain",
                  params: MATIC_CONNECTION_CONFIG,
                })
                .then(() => window.location.reload())
                .catch(() =>
                  setMessage(
                    "Sorry, we weren't able to switch the network. Please make sure you're connected to Polygon Mainnet."
                  )
                );
            } else {
              window.location.reload();
            }
          });
        } else {
          ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: MATIC_CONNECTION_CONFIG,
            })
            .then(() => {
              window.location.reload();
            })
            .catch(() =>
              setMessage(
                "Sorry, we weren't able to switch the network. Please make sure you're connected to Polygon Mainnet."
              )
            );
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.open(
          "https://metamask.app.link/dapp/www.distractionpoetry.com/"
        );
      } else {
        setMessage("Please install MetaMask.");
      }
    }
  }, [populatedPositions]);

  const populatePositions = useCallback(async () => {
    const newPositions = [...positions];
    for (const [index, pos] of newPositions.entries()) {
      const { nft_id, imageUrl } = pos;
      if (nft_id) {
        if (typeof imageUrl === "undefined") {
          let imageUrl;
          let name;
          let font;
          let backgroundColor;
          let wordColor;
          try {
            const res = await fetch(
              `${process.env.REACT_APP_API_URL}/nft/${nft_id}`
            );
            const { data = [] } = await res.json();
            const [nft = {}] = data;
            const {
              image_url,
              name: _name,
              font: _font,
              background_color,
              word_color,
            } = nft;
            imageUrl = `${PINATA_URL}/${image_url.slice("ipfs://".length)}`;
            name = _name;
            font = _font;
            backgroundColor = background_color;
            wordColor = word_color;
          } catch (err) {
            console.error(err);
          }
          newPositions[index].imageUrl = imageUrl || "";
          newPositions[index].name = name || "";
          newPositions[index].font = font || "";
          newPositions[index].backgroundColor = backgroundColor || "";
          newPositions[index].wordColor = wordColor || "";
        }
      }
      const _activeLock = locks.find((lock) => {
        return pos.id === lock.position_id && lock.active;
      });
      newPositions[index].locked = _activeLock ? true : false;
      if (index === newPositions.length - 1) {
        setPopulatedPositions(newPositions);
      }
    }
    setLoading(false);
  }, [setLoading, account, nftIds, positions, locks, setPopulatedPositions]);

  useEffect(() => {
    if (positions.length && locks) populatePositions();
  }, [positions, locks, populatePositions]);

  useEffect(() => {
    if (!loading && populatedPositions.length && locks) {
      handleConnect();
    }
  }, [loading]);

  useEffect(() => {
    if (allNftIds.length) {
      const newPositions = populatedPositions.map((pos) => {
        const { nft_id } = pos;
        if (allNftIds.includes(nft_id)) {
          return {
            ...pos,
            mine: true,
          };
        }
        return pos;
      });
      setPopulatedPositions(newPositions);
    }
  }, [allNftIds]);

  const handleSharedContainerDrop = useCallback(
    (position, item) => {
      fetch(`${process.env.REACT_APP_API_URL}/positions/${position.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nftId: item.id,
        }),
      }).catch(console.error);
      const { imageUrl, wordColor, backgroundColor, font, name } = item;
      const newNfts = nfts.map((nft) => {
        if (nft.id === item.id) {
          return {};
        } else {
          return nft;
        }
      });
      const newPositions = populatedPositions.map((pos) => {
        if (pos.id === position.id) {
          return {
            ...pos,
            nft_id: item.id,
            mine: true,
            imageUrl,
            name,
            font,
            wordColor,
            backgroundColor,
          };
        } else if (pos.nft_id === item.id) {
          return {
            id: pos.id,
            nft_id: null,
            mine: false,
            imageUrl: "",
            name: "",
            font: "",
            wordColor: "",
            backgroundColor: "",
          };
        } else {
          return pos;
        }
      });
      if (allNftIds.includes(position.nft_id) && position.nft_id !== item.id) {
        const bumpedNft = allNfts.find((nft) => nft.id === position.nft_id);
        setNfts([...newNfts, bumpedNft]);
      } else {
        setNfts(newNfts);
      }
      setPopulatedPositions(newPositions);
    },
    [allNfts, nftIds, nfts, setNfts, populatedPositions, setPopulatedPositions]
  );

  const handleOwnContainerDrop = useCallback(
    (item) => {
      const newPositions = populatedPositions.map((pos) => {
        if (pos.nft_id === item.id) {
          fetch(`${process.env.REACT_APP_API_URL}/positions/${pos.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              nftId: null,
            }),
          }).catch(console.error);
          return {
            ...pos,
            nft_id: null,
            imageUrl: false,
            mine: false,
            name: "",
            backgroundColor: "",
            wordColor: "",
            font: "",
          };
        } else {
          return pos;
        }
      });
      setPopulatedPositions(newPositions);
      const _nftIds = nfts.map(({ id }) => id);
      if (!_nftIds.includes(item.id)) {
        setNfts([...nfts, item]);
      }
    },
    [nfts, setNfts, populatedPositions, setPopulatedPositions]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_TYPES.NFT,
      drop: handleOwnContainerDrop,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [handleOwnContainerDrop]
  );

  const toggleLock = useCallback(
    (position) => {
      const { locked, mine } = position;
      if (!mine) return;
      fetch(`${process.env.REACT_APP_API_URL}/locks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userAddress: account.toLowerCase(),
          positionId: position.id,
          active: !locked,
        }),
      })
        .then((res) => {
          if (res.ok) {
            const newPositions = populatedPositions.map((pos) => {
              if (pos.id === position.id) {
                return {
                  ...pos,
                  locked: !locked,
                };
              } else {
                return pos;
              }
            });
            setPopulatedPositions(newPositions);
            let _activeLocks = locked ? activeLocks - 1 : activeLocks + 1;
            // hopefully unnecessary
            _activeLocks = _activeLocks < 0 ? 0 : _activeLocks;
            setActiveLocks(_activeLocks);
          }
        })
        .catch(console.error);
    },
    [
      activeLocks,
      setActiveLocks,
      account,
      locks,
      setLocks,
      populatedPositions,
      setPopulatedPositions,
    ]
  );

  const saveToIpfs = useCallback(() => {
    setMessage("Saving distraction poem to IPFS ...");
    const uuid = uuidv4();
    const _poemText = populatedPositions
      .reduce((previous, current = {}, _index) => {
        const index = _index + 1;
        const { name, font: _font, wordColor, backgroundColor } = current;
        let fontFamily = "serif";
        if (_font === "times new roman") {
          fontFamily = "'Times New Roman', serif";
        } else if (_font === "lobster") {
          fontFamily = "'Lobster', cursive";
        } else if (_font === "syncopate") {
          fontFamily = "'Syncopate', sans-serif";
        }
        const maybeBreak = index % 7 === 0 ? "</div><div>" : "";
        const _word = name
          ? `<span style="color: ${wordColor}; background-color: ${backgroundColor}; font-family: ${fontFamily};">${name}</span>`
          : "";
        const word = index % 7 === 0 || !_word.length ? _word : `${_word} `;
        return `${previous}${word}${maybeBreak}`;
      }, "<div>")
      .trim();
    const date = new Date().toLocaleString("en-US", {
      dateStyle: "long",
      timeStyle: "medium",
    });
    const poemText = `<html>
  <head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Lobster&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Lobster&family=Syncopate&display=swap" rel="stylesheet">
  </head>
  <body>
    <div style="font-family: monospace;">
      <div><b>distraction poem ${uuid}</b></div>
      <div>saved by ${account || "anon"}</div>
      <div>on ${date}</div>
      <br />
      <br />
      <div>
        ${_poemText}
      </div>
    </div>
  </body>
</html>`;
    fetch(`${process.env.REACT_APP_API_URL}/ipfs/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        poemText,
        address: account,
      }),
    })
      .then((res) => res.json())
      .then(({ data = {} }) => {
        const { url } = data;
        const _message = `Distraction poem saved to IPFS`;
        if (!url) {
          throw new Error("couldn't get pinned poem url");
        }
        setMessage(_message);
        setPoemUrl(url);
      })
      .catch(console.error);
  }, [account, populatedPositions, setMessage]);

  const clearMessage = useCallback(() => {
    setMessage("");
  }, [setMessage]);

  return (
    <>
      <Modal clearMessage={clearMessage} message={message} poemUrl={poemUrl} />
      {loading ? (
        <div style={{ marginTop: "120px", textAlign: "center" }}>
          <img style={{ margin: "0 auto" }} src="/spinny.svg" />
        </div>
      ) : (
        <>
          <Header
            hideConnect={false}
            account={account}
            handleConnect={handleConnect}
            showHamburgerMenu={showHamburgerMenu}
            setShowHamburgerMenu={setShowHamburgerMenu}
            saveToIpfs={saveToIpfs}
          />
          <CountdownBar timeLeft={timeLeft} timeToReset={TIME_TO_RESET} />
          <MainContainer id={"main-container"}>
            <SharedContainer>
              {populatedPositions.map((position, index) => {
                return (
                  <Position
                    key={`position-${index}`}
                    position={position}
                    index={index}
                    handleDrop={handleSharedContainerDrop}
                    toggleLock={toggleLock}
                    activeLocks={activeLocks}
                  />
                );
              })}
            </SharedContainer>
            <OwnContainer isOver={isOver} ref={drop}>
              {nfts.map(
                (
                  { id, imageUrl, name, backgroundColor, wordColor, font },
                  index
                ) => {
                  if (!id) return null;
                  return (
                    <Nft
                      key={`nft-${index}`}
                      id={id}
                      imageUrl={imageUrl}
                      name={name}
                      font={font}
                      backgroundColor={backgroundColor}
                      wordColor={wordColor}
                    />
                  );
                }
              )}
            </OwnContainer>
          </MainContainer>
        </>
      )}
    </>
  );
};

export default Main;
