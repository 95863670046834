import styled from "styled-components";
import { COLORS, STATUS } from "./constants";

const FlexContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 16px;

  padding: 16px;
  overflow-y: scroll;
  text-align: center;

  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}

  ${({ height }) =>
    height &&
    `
    height: ${height};
    max-height: ${height};
  `}
`;

const OwnContainer = styled(FlexContainer).attrs(({ isOver }) => ({
  backgroundColor: isOver ? COLORS.LIGHT_BLUE : COLORS.BLUE,
  height: "66px",
}))`
  border-top: 4px solid ${COLORS.WARM_GRAY};
  flex-flow: row;
  overflow-y: hidden;

  @media only screen and (min-width: 435px) {
    height: 72px;
    min-height: 72px;
  }

  @media only screen and (min-width: 650px) {
    height: 110px;
    min-height: 110px;
  }
`;

const SharedContainer = styled(FlexContainer).attrs(() => ({
  backgroundColor: COLORS.BLACK,
  height: "calc(100% - 66px)",
}))`
  @media only screen and (min-width: 435px) {
    height: calc(100% - 72px);
  }

  @media only screen and (min-width: 650px) {
    height: calc(100% - 110px);
  }
`;

const Square = styled.div`
  background-color: ${COLORS.TEXT_GRAY};
  border-radius: 3px;
  border-style: solid;
  border-width: 4px;
  position: relative;

  ${({ status }) => {
    let borderColor;

    if (status === STATUS.OVER) {
      borderColor = COLORS.GREEN;
    } else if (status === STATUS.EMPTY) {
      borderColor = COLORS.WARM_GRAY;
    } else if (status === STATUS.FULL) {
      borderColor = COLORS.COOL_GRAY;
    } else if (status === STATUS.MINE) {
      borderColor = COLORS.BLUE;
    } else if (status === STATUS.LOCKED) {
      borderColor = COLORS.DARK_RED;
    } else if (status === STATUS.MINE_LOCKED) {
      borderColor = COLORS.RED;
    } else if (status === STATUS.DRAGGING) {
      borderColor = COLORS.DARK_BLUE;
    } else if (status === STATUS.OVER_LOCKED) {
      borderColor = COLORS.RED;
    } else {
      borderColor = COLORS.WARM_GRAY;
    }

    return `border-color: ${borderColor};`;
  }}

  height: 57px;
  min-height: 57px;
  min-width: 90px;

  img {
    height: 57px;
    width: 90px;
  }

  @media only screen and (min-width: 435px) {
    height: 62px;
    min-height: 62px;
    min-width: 110px;

    img {
      height: 62px;
      width: 110px;
    }
  }

  @media only screen and (min-width: 650px) {
    min-height: 102px;
    min-width: 180px;

    img {
      height: 102px;
      width: 180px;
    }
  }
`;

const ConnectButton = styled.button`
  background-color: ${COLORS.BLACK};
  border: none;
  color: ${COLORS.WHITE};
  cursor: pointer;
  font-family: monospace;
  font-size: 16px;
  margin-top: -4px;

  @media (min-width: 680px) {
    font-size: 18px;
  }
`;

const HeaderContainer = styled.div`
  background-color: ${COLORS.BLACK};
  position: fixed;
  padding: 12px 0 10px 0;
  width: 100%;
  top: 0;
  text-align: center;

  @media (min-width: 847px) {
    display: grid;
    ${({ hideConnect }) =>
      `grid-template-columns: repeat(${hideConnect ? "5" : "7"}, auto);`}
  }

  @media (min-width: 680px) {
    font-size: 16px;
  }
`;

const HeaderSection = styled.div`
  display: none;
  height: 22px;

  a {
    color: inherit;
    text-decoration: none;
  }

  @media (min-width: 847px) {
    display: block;
  }
`;

const MainContainer = styled.div`
  padding-top: 42px;
`;

const Hamburger = styled.div`
  cursor: pointer;
  margin: 0 calc(50% - 26px);

  div {
    background-color: ${COLORS.WHITE};
    width: 36px;
    height: 2px;
    border-radius: 3px;
    margin: 6px 0;
  }

  @media (min-width: 847px) {
    display: none;
  }
`;

const HamburgerMenu = styled.div`
  position: absolute;
  display: grid;
  ${({ hideConnect }) =>
    `grid-template-rows: repeat(${hideConnect ? "5" : "7"}, 28px);`}
  width: 100%;
  z-index: 1;
  background-color: ${COLORS.BLACK};
  padding: 6px 0 14px 0;
  top: 44px;
  left: 0;

  @media (min-width: 847px) {
    display: none;
  }
`;

const HamburgerSection = styled.div`
  width: 100%;
  text-align: center;
  height: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const AboutContainer = styled.div`
  padding: 46px 18px 24px 18px;

  ${({ centerItems }) => centerItems && "text-align: center;"}

  color: ${COLORS.WARM_GRAY};

  div {
    margin-bottom: 20px;
    line-height: 132%;

    a {
      color: inherit;
    }
  }

  video {
    margin: 18px auto 0 auto;
    max-width: 100%;
    width: 596px;
  }
`;

const ChapbookContainer = styled.div`
  padding: 60px 18px 24px 18px;
  text-align: center;
`;

const LockButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 14px;
  height: 17px;
  padding: 0;
  border-radius: 0;
  border-style: none;
  background-color: white;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
`;

const ModalContainer = styled.div`
  width: 280px;
  background-color: ${COLORS.DARK_BLUE};
  position: absolute;
  padding: 18px;
  top: 30%;
  left: calc(50% - 140px);
  text-align: center;
  z-index: 100;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const MessageContainer = styled.div`
  width: 100%;
  line-height: 140%;

  a {
    color: inherit;
  }
`;

const DismissButton = styled.button`
  background-color: ${COLORS.TEXT_GRAY};
  border-style: solid;
  border-color: ${COLORS.COOL_GRAY};
  border-width: 2px;
  border-radius: 0;
  width: 120px;
  height 34px;
  color: inherit;
  margin-top: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  color: ${COLORS.COOL_GRAY};
`;

const OkayButton = styled.button`
  background-color: ${COLORS.TEXT_GRAY};
  border-style: solid;
  border-color: ${COLORS.WARM_GRAY};
  border-width: 2px;
  border-radius: 0;
  width: 120px;
  height 34px;
  color: inherit;
  margin-top: 28px;
  margin-bottom: 12px;
  margin-left: 18px;
  cursor: pointer;
`;

const StyledCountdownBar = styled.div`
  height: 3px;
  background-color: ${COLORS.BLUE};
  ${({ timeoutRatio }) => `width: ${timeoutRatio}%;`}
  position: absolute;
  left: 0;
  top: 0;
  transition: width 1s linear;
`;

export {
  OwnContainer,
  SharedContainer,
  Square,
  ConnectButton,
  HeaderContainer,
  HeaderSection,
  MainContainer,
  Hamburger,
  HamburgerMenu,
  HamburgerSection,
  AboutContainer,
  LockButton,
  ModalContainer,
  MessageContainer,
  DismissButton,
  OkayButton,
  StyledCountdownBar,
  ChapbookContainer,
};
