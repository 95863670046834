import {
  ConnectButton,
  HeaderContainer,
  HeaderSection,
  Hamburger,
  HamburgerMenu,
  HamburgerSection,
} from "./styles";

const Header = ({
  account,
  handleConnect,
  showHamburgerMenu,
  setShowHamburgerMenu,
  hideConnect,
  saveToIpfs,
}) => {
  return (
    <>
      <HeaderContainer hideConnect={hideConnect}>
        <HeaderSection>
          <a href="/">HOME</a>
        </HeaderSection>
        <HeaderSection>
          <a href="/about">ABOUT</a>
        </HeaderSection>
        <HeaderSection>
          <a href="/poetry">POETRY</a>
        </HeaderSection>
        <HeaderSection>
          <a
            href="https://discord.gg/dNCXhPt3"
            target="_blank"
            rel="noreferrer noopener"
          >
            DISCORD
          </a>
        </HeaderSection>
        {hideConnect ? null : (
          <HeaderSection style={{ cursor: "pointer" }} onClick={saveToIpfs}>
            SAVE TO IPFS
          </HeaderSection>
        )}
        <HeaderSection>
          <a
            href="https://opensea.io/collection/distractionpoetry"
            target="_blank"
            rel="noreferrer noopener"
          >
            OPENSEA
          </a>
        </HeaderSection>
        {hideConnect ? null : (
          <HeaderSection>
            {account ? (
              `${account.slice(0, 8)}…`
            ) : (
              <ConnectButton onClick={handleConnect}>
                {"[ CONNECT ]"}
              </ConnectButton>
            )}
          </HeaderSection>
        )}
        <Hamburger onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}>
          <div />
          <div />
          <div />
        </Hamburger>
      </HeaderContainer>
      {showHamburgerMenu ? (
        <HamburgerMenu hideConnect={hideConnect}>
          <HamburgerSection>
            <a href="/">HOME</a>
          </HamburgerSection>
          <HamburgerSection>
            <a href="/about">ABOUT</a>
          </HamburgerSection>
          <HamburgerSection>
            <a href="/poetry">POETRY</a>
          </HamburgerSection>
          <HamburgerSection>
            <a
              href="https://discord.gg/dNCXhPt3"
              target="_blank"
              rel="noreferrer noopener"
            >
              DISCORD
            </a>
          </HamburgerSection>
          {hideConnect ? null : (
            <HamburgerSection
              style={{ cursor: "pointer" }}
              onClick={saveToIpfs}
            >
              SAVE TO IPFS
            </HamburgerSection>
          )}
          <HamburgerSection>
            <a
              href="https://opensea.io/collection/distractionpoetry"
              target="_blank"
              rel="noreferrer noopener"
            >
              OPENSEA
            </a>
          </HamburgerSection>
          {hideConnect ? null : (
            <HamburgerSection>
              {account ? (
                `${account.slice(0, 8)}…`
              ) : (
                <ConnectButton onClick={handleConnect}>
                  {"[ CONNECT ]"}
                </ConnectButton>
              )}
            </HamburgerSection>
          )}
        </HamburgerMenu>
      ) : null}
    </>
  );
};

export default Header;
