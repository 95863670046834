import { useDrag } from "react-dnd";
import { Square } from "./styles";
import { DRAGGABLE_TYPES, STATUS } from "./constants";

const Nft = ({ id, imageUrl, name, backgroundColor, wordColor, font }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      item: { id, imageUrl, name, backgroundColor, wordColor, font },
      type: DRAGGABLE_TYPES.NFT,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [id, imageUrl, name, backgroundColor, wordColor, font]
  );

  return (
    <Square
      ref={drag}
      status={
        isDragging ? STATUS.DRAGGING : "" /* TODO: make border dark gray*/
      }
    >
      <img src={imageUrl} />
    </Square>
  );
};

export default Nft;
