import { useEffect } from "react";
import {
  ModalContainer,
  MessageContainer,
  DismissButton,
  OkayButton,
} from "./styles";

const Modal = ({ message, clearMessage, poemUrl }) => {
  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", () => {});
  }, []);

  return (
    <>
      {message ? (
        <ModalContainer>
          <MessageContainer dangerouslySetInnerHTML={{ __html: message }} />
          <DismissButton onClick={clearMessage}>OK</DismissButton>
          {poemUrl && (
            <a href={poemUrl} target={"_blank"} rel={"noreferrer noopener"}>
              <OkayButton onClick={() => {}}>SEE POEM</OkayButton>
            </a>
          )}
        </ModalContainer>
      ) : null}
    </>
  );
};

export default Modal;
