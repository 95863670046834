import { useEffect, useState } from "react";
import Header from "./Header";
import { AboutContainer } from "./styles";

const About = () => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const aboutContainer = document.querySelector("#about-container");

  useEffect(() => {
    if (aboutContainer) {
      aboutContainer.addEventListener("click", () => {
        if (showHamburgerMenu) {
          setShowHamburgerMenu(false);
        }
      });
    }
  }, [aboutContainer]);

  return (
    <>
      <Header
        hideConnect={true}
        showHamburgerMenu={showHamburgerMenu}
        setShowHamburgerMenu={setShowHamburgerMenu}
      />
      <AboutContainer id={"about-container"}>
        <h2>Distraction Poetry ...</h2>
        <div>
          ...is what we're all feeling right now. It's about us containing
          multitudes and the online spaces we inhabit containing even more
          multitudes and the distraction, both good and bad, all of that
          becomes. It's flipping between the real world and the digital world
          like they're tabs on the same device. It's the insane multi-tasking we
          now think is normal. It's the constant onslaught of dings, pings and
          buzzes. It's using all the mediums all at once because you gotta
          leverage your content, it's not poetry, it's CONTENT!!! It's
          regurgitating dark memories as you text with a stranger who you kinda
          want to sleep with. It's thinking maybe you need to work through that,
          but you can't, because people won't stop for you to figure your shit
          out, they're making it, social media says so. It's the intense
          pressure that builds at being expected to stay up-to-speed when the
          speed is constantly increasing. It's having some really fucked up
          thoughts. It's having no thoughts. It's trying to find symbolism in
          the madness. It's messy and brash and a digression. It's the fact that
          I've looked at my phone 12 times since starting to write this
          introduction.
        </div>
        <div>
          If you'd like a physical copy of the poetry chapbook, email me at{" "}
          <a href="mailto:baileyreutzel@gmail.com">baileyreutzel@gmail.com</a>.
        </div>
        <div>
          If you want NFTs to play around with on the site,{" "}
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfq_a08GtVijv5q9Oo-5-zbqpXiuP80imlUMl2vwl2xUv9cSQ/viewform?usp=sf_link">
            fill in this form
          </a>{" "}
          and then hold tight because I have to manually airdrop them to you.
        </div>
        <div>***</div>
        <div>This project is the brainchild of Bailey Reutzel.</div>
        <div>
          <img src="/bailey.jpeg" />
        </div>
        <div>
          <a
            href="https://www.instagram.com/blurredworld/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/insta.svg" width={"24px"} height={"24px"} />
          </a>
          <a
            href="https://twitter.com/BLR13"
            target="_blank"
            rel="noreferrer noopener"
            style={{ marginLeft: "8px" }}
          >
            <img src="/twitter.svg" width={"24px"} height={"24px"} />
          </a>
        </div>
        <div>
          <b>Bailey Reutzel</b> has spent her entire career in crypto and it's
          taken a toll on her psyche, but she's hanging in there. Long-time
          crypto journalist, more recent crypto events producer, and always and
          forever creative writer and poet.
        </div>
        <div>***</div>
        <div>Video and audio editing by Eric Holmes.</div>
        <div>
          <img src="/hamslime.jpeg" />
        </div>
        <div>
          <b>Eric Holmes (aka: Hamslime)</b> is some dude that likes movies. So
          much so that he wastes his time bloviating as the lesser third of the{" "}
          <a href="https://podcasts.apple.com/us/podcast/find-your-film/id1524946883">
            Find Your Film
          </a>{" "}
          podcast with his much more astute and eloquent co-hosts Greg
          Srisavasdi and Bruce Purkey.
        </div>
        <div>***</div>
        <div>
          <b>A grant project for SXSW funded by Protocol Labs</b>
        </div>
        Distraction Poetry is built with decentralized storage IPFS and
        Filecoin, which means: haha this shit is FOREVER!
      </AboutContainer>
    </>
  );
};

export default About;
