import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./styles.css";
import Main from "./Main";
import About from "./About";
import Video from "./Video";
import Chapbook from "./Chapbook";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="about" element={<About />} />
      <Route
        path="misophonia"
        element={<Video srcUrl={"/Mouth_Noises_2.mp4"} />}
      />
      <Route
        path="he-can-write-a-poem"
        element={<Video srcUrl={"/Fucked_Up_4_1.mp4"} />}
      />
      <Route path="fidget-spinners" element={<Video />} />
      <Route path="poetry" element={<Chapbook />} />
    </Routes>
  );
};

export default App;
